import { apiHttpService } from '@/api'
import { getWorkspaceId, getRouteDetailId } from '@/api/utils/route_params'
import { arraySerializer } from '@/api/utils/serializer'
import { setPaginationParams } from '@/api/utils/pagination_params'
import {
  getISOStringDateTimeGte,
  getISOStringDateTimeLte,
  parseISODateString
} from '@/api/utils/iso_date'

const mapResponse = response => {
  return {
    id: response.id,
    schedule: response.report_schedule,
    documentCreatedFrom: response.document_created_gte,
    documentCreatedTo: response.document_created_lte,
    format: response.report_format,
    fileName: response.report_name,
    status: response.completed_at ? 'created' : 'processing',
    url: response.report,
    createdAt: response.created_at,
    completedAt: response.completed_at
  }
}

const processResponseList = results => results.map(result => {
  return mapResponse(result)
})

export default {
  async fetchDetail () {
    try {
      const response = await apiHttpService({
        url: '/workspaces/' + getWorkspaceId() + '/document-reports/' + getRouteDetailId(),
        method: 'GET',
        transformResponse: [data => {
          return mapResponse(data)
        }]
      })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async fetchList (queryParams) {
    let params = {}
    if (queryParams.activeFilters) {
      params = {
        created_at_gte: getISOStringDateTimeGte(queryParams.activeFilters.createdDateFrom),
        created_at_lte: getISOStringDateTimeLte(queryParams.activeFilters.createdDateTo),
        report_schedule: queryParams.activeFilters.reportSchedule
      }
    }

    setPaginationParams(queryParams, params, 'reports')

    try {
      const response = await apiHttpService({
        url: '/workspaces/' + getWorkspaceId() + '/document-reports/',
        method: 'GET',
        params: arraySerializer(params)
      })
      const processedResponse = {
        itemCount: response.data.count,
        pageCount: response.data.total_pages,
        items: processResponseList(response.data.results)
      }
      return Promise.resolve(processedResponse)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async submitData (form) {
    try {
      const response = await apiHttpService({
        url: '/workspaces/' + getWorkspaceId() + '/document-reports/',
        method: 'POST',
        data: {
          document_created_gte: parseISODateString(form.documentCreatedFrom),
          document_created_lte: parseISODateString(form.documentCreatedTo),
          format: form.format
        }
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
