import { DateTime } from 'luxon'
import { dateTimeFormats } from '@/translation/datetime'

export const getDateTimeValue = {
  methods: {
    getDateTimeValue (value, type) {
      try {
        return value
          ? this.$d(new Date(value), type)
          : ''
      } catch (_) {
        return ''
      }
    }
  }
}

export const getZonedDateTime = {
  methods: {
    getTimeZone (isoDate, zone) {
      return DateTime
        .fromISO(isoDate, { zone: zone })
        .setLocale(this.$i18n.locale)
        .offsetNameLong
    },
    getZonedDateTime (value, type, zone) {
      const locale = this.$i18n.locale
      try {
        return value
          ? DateTime
            .fromISO(value, { zone: zone })
            .setLocale(locale)
            .toLocaleString(dateTimeFormats[locale][type])
          : ''
      } catch (_) {
        return ''
      }
    }
  }
}
