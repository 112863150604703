<template>
  <div class="d-md-flex py-2">
    <div
      class="d-md-flex text-overline flex-grow-0 flex-shrink-1"
      :style="titleFlexBasis"
    >
      <slot name="title">
        {{ title }}
      </slot>
    </div>
    <div
      class="d-md-flex text--primary flex-grow-1 flex-shrink-1 pt-1"
      style="flex-basis: 250px"
    >
      <slot name="content">
        {{ content }}
      </slot>
    </div>
    <div
      class="d-md-flex text--primary flex-grow-0 flex-shrink-1 pt-1"
    >
      <slot name="action" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TitleAndContentRow',
  props: {
    title: { type: String, default: '' },
    content: { type: String, default: '' },
    titleFlexBasis: { type: String, default: 'flex-basis: 25%' }
  }
}
</script>
