import { endOfDay, parseISO } from 'date-fns'

const convertStringToISODate = value => {
  return parseISO(value)
}

export const getISOStringDateTimeGte = date => {
  return date
    ? convertStringToISODate(date)
    : date
}

export const getISOStringDateTimeLte = date => {
  return date
    ? endOfDay(convertStringToISODate(date), { fractionDigits: 3 })
    : date
}

export const parseISODateString = value => {
  return value
    ? convertStringToISODate(value)
    : undefined
}
