<template>
  <v-chip
    v-if="status"
    :small="small"
    :input-value="true"
    :color="`${statusMap[status].color}--text`"
  >
    <v-icon
      :small="small"
      left
    >
      {{ statusMap[status].icon }}
    </v-icon>
    {{ $t(`general.${status}`) }}
  </v-chip>
</template>

<script>
export default {
  name: 'StatusChip',
  props: {
    status: { type: String, default: '' },
    small: { type: Boolean, default: false }
  },
  data () {
    return {
      statusMap: {
        processing: {
          color: 'info',
          icon: this.$vuetify.icons.values.loading
        },
        error: {
          color: 'error',
          icon: this.$vuetify.icons.values.error
        },
        success: {
          color: 'success',
          icon: this.$vuetify.icons.values.check
        },
        created: {
          color: 'success',
          icon: this.$vuetify.icons.values.check
        },
        active: {
          color: 'success',
          icon: this.$vuetify.icons.values.check
        },
        inactive: {
          color: 'grey',
          icon: this.$vuetify.icons.values.inactive
        },
        sent: {
          color: 'success',
          icon: this.$vuetify.icons.values.check
        },
        payment_failed: {
          color: 'error',
          icon: this.$vuetify.icons.values.error
        },
        paymentMethodExpired: {
          color: 'error',
          icon: this.$vuetify.icons.values.error
        },
        paymentMethodInUse: {
          color: 'primary'
        },
        cancelled: {
          color: 'grey',
          icon: this.$vuetify.icons.values.clear
        },
        completed: {
          color: 'success',
          icon: this.$vuetify.icons.values.check
        },
        scheduled: {
          color: 'info',
          icon: this.$vuetify.icons.values.loading
        }
      }
    }
  }
}
</script>
