<template>
  <v-row>
    <v-col
      cols="12"
    >
      <title-and-content-row :title="$t('general.fileName')">
        <template v-slot:content>
          <div>
            <strong>{{ fileName }}</strong>
            <div class="text-caption grey--text text--darken-2">
              {{ $t('reports.completedAt') }} {{ getDateTimeValue(detailData.createdAt, 'long') }}
            </div>
          </div>
        </template>
      </title-and-content-row>
      <v-divider />
      <title-and-content-row
        :title="$t('reports.format')"
        :content="detailData.format"
      />
      <v-divider />
      <title-and-content-row
        :title="$t('reports.schedule')"
      >
        <template v-slot:content>
          {{ getScheduleString(detailData.schedule) }}
          <v-spacer />
          <v-btn
            v-if="detailData.schedule"
            outlined
            rounded
            small
            :to="{
              name: 'ReportSchedulesDetails',
              params: {
                workspaceId: $route.params.workspaceId,
                routeDetailId: detailData.schedule
              }
            }"
          >
            <v-icon left>
              {{ $vuetify.icons.values.schedule }}
            </v-icon>
            {{ $t('general.settings') }}
          </v-btn>
        </template>
      </title-and-content-row>
      <v-divider />
      <title-and-content-row
        :title="$t('reports.period')"
      >
        <template v-slot:content>
          <div>
            <strong>From</strong>
            <div>{{ getDateTimeValue(detailData.documentCreatedFrom, 'long') }}</div>
            <strong>To</strong>
            <div>{{ getDateTimeValue(detailData.documentCreatedTo, 'long') }}</div>
          </div>
        </template>
      </title-and-content-row>
      <v-divider />
      <title-and-content-row
        :title="$t('reports.createdAt')"
        :content="getDateTimeValue(detailData.createdAt, 'long')"
      />
    </v-col>
  </v-row>
</template>

<script>
import { getDateTimeValue } from '@/mixins/dates'
import { getScheduleString } from '@/mixins/reports'
import TitleAndContentRow from '@/components/layout/TitleAndContentRow'

export default {
  name: 'DocumentReportsDetailOverview',
  components: {
    TitleAndContentRow
  },
  mixins: [
    getDateTimeValue,
    getScheduleString
  ],
  props: {
    detailData: { type: Object, default: () => { return {} } },
    loadingData: { type: Boolean, default: false },
    fileName: { type: String, default: '' }
  }
}
</script>
