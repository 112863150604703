<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col cols="12">
        <details-toolbar
          :title="$t('reports.detail.cardInfoTitle')"
          :detail-data="detailData"
        >
          <template v-slot:chip>
            <status-chip :status="detailData.status" />
          </template>
        </details-toolbar>
        <card-layout
          disable-title
          enable-actions
        >
          <template v-slot:content>
            <document-reports-detail-overview
              :detail-data="detailData"
              :loading-data="loadingData"
              :file-name="fileName"
            />
          </template>
          <template v-slot:actions>
            <v-btn
              color="primary"
              text
              rounded
              small
              target="_blank"
              :disabled="!detailData.url"
              :href="detailData.url"
            >
              <v-icon left>
                {{ $vuetify.icons.values.download }}
              </v-icon>
              {{ $t('reports.download') }}
            </v-btn>
          </template>
        </card-layout>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from '@/api/modules/reports'
import { getDateTimeValue } from '@/mixins/dates'
import DetailsToolbar from '@/components/toolbars/DetailsToolbar'
import CardLayout from '@/components/layout/CardLayout'
import StatusChip from '@/components/chips/StatusChip'
import DocumentReportsDetailOverview from '@/components/details/reports/Overview'

export default {
  name: 'DocumentReportsDetails',
  components: {
    DetailsToolbar,
    CardLayout,
    StatusChip,
    DocumentReportsDetailOverview
  },
  mixins: [getDateTimeValue],
  data () {
    return {
      activeTab: 'repHead',
      detailData: {},
      loadingData: false
    }
  },
  computed: {
    fileName () {
      return this.detailData.fileName || this.$t('general.na')
    }
  },
  mounted () {
    this.getDetailData()
  },
  methods: {
    async getDetailData () {
      this.loadingData = true
      const result = await api.fetchDetail()
      this.detailData = { ...result }
      this.loadingData = false
    }
  }
}
</script>
